import React from "react"
import "../assets/styles/global.css"

import FullPageLayout from "../layouts/FullPageLayout"
import Section from "../components/Section/Section"
import Footer from "../components/Bar/footer"
import ContactCTA from "../components/Content/Contact/ContactCTA"
import ContactForm from "../components/Content/Contact/ContactForm"
import { CONTACT_US_TOOLTIPS } from "../const/MenuTooltips"
import { SEO_OPTIONS_CONTACT_US } from "../const/SEOOptions"

const ContactPage = ({location}) => {

  const fullpageOptions = {
    navigationTooltips: CONTACT_US_TOOLTIPS
  }

  return (
    <FullPageLayout
      options={fullpageOptions}
      SEOOptions={SEO_OPTIONS_CONTACT_US}

      location={location}
    >

      <Section isFirstSection
        Background={"transparent"}
        SectionMinHeight={"100vh"}
      >
        <div style={{
          margin:'auto'
        }}>
          <ContactCTA />
        </div>
      </Section>

      <Section
        Background={"transparent"}
      >
        <ContactForm />
        <Footer location={location} />
      </Section>


    </FullPageLayout>
  )
}

export default ContactPage